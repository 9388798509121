
import { defineComponent, ref } from 'vue'
import { TokenService } from '@/services/TokenService'
import { CheckCircleFilled } from '@ant-design/icons-vue'
import { AccountService } from '@/services/AccountService'
import Footer from '@/assets/components/Footer.vue'

export default defineComponent({
  name: 'PasswordRecovery',

  components: {
    CheckCircleFilled,
    Footer
  },

  data: () => ({
    email: null as unknown,
    insertMail: ref<boolean>(true),
    sendMail: ref<boolean>(false),
    recoverAccount: ref<boolean>(false),
    resetAccount: ref<boolean>(false),
    password: null as unknown,
    passwordConfirmation: null as unknown,
    mailNotFound: false as boolean,
    service: null as any,
    differentPasswords: false as boolean,
    version: ref<any>(process.env.VUE_APP_VERSION)
  }),

  created () {
    const key = this.$route.query.key?.toString()

    if (key) {
      TokenService.setToken(key!)
      this.insertMail = false
      this.recoverAccount = true
    }

    this.service = AccountService.getInstance()
  },

  beforeUnmount () {
    TokenService.clear()
  },

  computed: {
    titleMain () {
      if (this.insertMail) {
        return 'Digite o e-mail da sua conta'
      }
      if (this.recoverAccount) {
        return 'Redefinir sua senha'
      }
      if (this.mailNotFound) {
        return 'Nenhuma conta encontrada'
      }

      return ''
    },

    title () {
      if (this.insertMail) {
        return 'Digite o e-mail da sua conta'
      }

      if (this.sendMail || this.resetAccount) {
        return 'Recuperação de senha'
      }

      if (this.recoverAccount) {
        return 'Redefinir senha'
      }

      return ''
    },

    subTitle () {
      if (this.sendMail) {
        return 'O link de recuperação de conta foi enviado para o seu e-mail'
      }

      if (this.mailNotFound) {
        return 'Por favor verifique o e-mail informado e tente novamente'
      }

      if (this.resetAccount) {
        return 'Sua senha foi alterada com sucesso.'
      }

      return ''
    },

    buttonText () {
      if (this.insertMail) {
        return 'RECUPERAR SENHA'
      }

      if (this.sendMail || this.resetAccount) {
        return 'VOLTAR PARA O LOGIN'
      }

      if (this.recoverAccount) {
        return 'SALVAR'
      }

      if (this.mailNotFound) {
        return 'VOLTAR'
      }

      return ''
    }
  },

  methods: {
    async nextStep () {
      if (this.insertMail) {
        try {
            await this.service.sendEmailRecoveryPassword({
                email: this.email
            })

            this.sendMail = true
            this.insertMail = false
        } catch (err) {
            console.error(err)
            this.sendMail = false
            this.insertMail = true
        }
        return
      }

      if (this.resetAccount || this.sendMail) {
        this.$router.replace({ name: 'authenticator' })
        return
      }

      if (this.recoverAccount) {
        if (this.password !== this.passwordConfirmation) {
          this.differentPasswords = true
           setTimeout(() => {
            this.differentPasswords = false
           }, 3200)
        } else {
          try {
            await this.service.sendSenhaRecover({
              password: this.password
            })

            TokenService.clear()

            this.recoverAccount = false
            this.differentPasswords = false
            this.resetAccount = true
          } catch (err) {
            console.error(err)
          }
        }
      }
    }
  }
})
