
import { defineComponent, ref } from 'vue'
import { mapState, mapActions } from 'vuex'

export default defineComponent({
  name: 'FooterContent',
  data () {
    return {
      current: ref(1),
      statusPage: ref(1),
      folderIdPage: ref(1)
    }
  },
  watch: {
    async current () {
      await this.setPage(this.current)
      this.setPagePagination(this.current)
    }
  },
  computed: {
    ...mapState('sections', ['envelopes', 'folderId', 'vaultSelected', 'statusList', 'currentStatus', 'pagePagination']),
    pages (): number {
      return this.envelopes !== null ? this.envelopes?.pageTotal * 10 : 0
    },
    size (): number {
      return this.envelopes !== null ? this.envelopes?.pageSize : 0
    },
    total (): number {
      return this.envelopes !== null ? this.envelopes?.total : 0
    }
  },
  mounted () {
    this.statusPage = this.currentStatus
    this.folderIdPage = this.folderId
  },
  updated () {
    if (this.statusPage !== this.currentStatus) {
      this.statusPage = this.currentStatus
      this.current = 1
    }
    if (this.folderIdPage !== this.folderId) {
        this.folderIdPage = this.folderId
        this.current = 1
    }
  },
  methods: {
    ...mapActions('sections', ['fetchEnvelops', 'fetchEnvelopesByUser', 'fetchEnvelopesByVault', 'setPagePagination']),

    async setPage (value: any) {
      this.current = value
      if (this.currentStatus === null && !this.vaultSelected) {
        this.fetchEnvelops({
          folderId: this.folderIdPage,
          page: this.current,
          size: 9
        })
      } else if (this.vaultSelected) {
        this.fetchEnvelopesByVault({
          folderId: this.folderIdPage,
          status: null,
          page: this.current,
          size: 9
         })
      } else {
        this.fetchEnvelopesByUser(
          { page: this.current, size: 9, status: this.currentStatus, filtered: null }
        )
      }
    }
  }
})
