
import { defineComponent, ref } from 'vue'
import { FolderService } from '@/services/FolderService'
import { mapActions, mapState } from 'vuex'
import { LockOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'FolderSelectHome',
  components: {
    LockOutlined
  },
  data () {
    return {
      folderService: ref<FolderService | null>(null),
      cofreSelected: ref<{ value: any, label: string }>({ value: null, label: '' }),
      selectTreeData: ref<any>([])
    }
  },
  watch: {
    treeSelected () {
      const treeSelected = Number(this.treeSelected[0])
      const folderId = Number(localStorage.getItem('folderId'))
      if (treeSelected !== 0 && treeSelected === folderId) {
        const selectedNode = this.selectTreeData.find((node: any) => node.value === treeSelected)
        if (selectedNode) {
          this.cofreSelected.value = selectedNode.value
          this.cofreSelected.label = selectedNode.title
        }
      } else {
        this.cofreSelected.value = null
        this.cofreSelected.label = ''
      }
    }
  },
  computed: {
    ...mapState('sections', ['folderId', 'currentStatus', 'treeSelected'])
  },
  methods: {
    ...mapActions('sections', [
      'setFolderId',
      'setFolderName'
    ]),
    async getFolder () {
      const folders = await this.folderService?.getSelectView()
      if (folders) {
        this.selectTreeData = this.transformData(folders.childrens || [])
      }
    },
    transformData (nodes: any, parentId = 0) {
      return nodes.map((node: any) => {
        const transformedNode = {
          title: node.name,
          value: node.id,
          key: node.id,
          children: node.childrens ? this.transformData(node.childrens) : []
        }
        return transformedNode
      })
    },
    findItemInTree (treeData: any, targetValue: any) {
      for (const node of treeData) {
        if (node.value === targetValue) {
          return node
        }

        if (node.children && node.children.length > 0) {
          const foundInChildren: any = this.findItemInTree(node.children, targetValue)
          if (foundInChildren) {
            return foundInChildren
          }
        }
      }
      return null
    },
    formatInfoCofreSelected (cofreSelected: any) {
      const foundItem = this.findItemInTree(this.selectTreeData, cofreSelected)
      return foundItem ? { id: foundItem.value, name: foundItem.title } : null
    },
    handleChangeCofre (value: any) {
      const infoCofreSelected = this.formatInfoCofreSelected(value)
      this.$emit('handleChangeCofre', value, infoCofreSelected)
    }
  },
  async mounted () {
    this.folderService = FolderService.getInstance()
    await this.getFolder()

    if (!localStorage.getItem('folderId') && this.selectTreeData.length > 0) {
      const firstNode = this.selectTreeData[0]
      this.setFolderId(parseInt(firstNode.value))
      this.setFolderName(firstNode.title)
    }
  },
  updated () {
    if (String(localStorage.getItem('folderId')) !== String(this.cofreSelected.value)) {
      const foundItem = this.findItemInTree(this.selectTreeData, Number(localStorage.getItem('folderId')))
      if (foundItem) {
        this.cofreSelected.value = foundItem.value
        this.cofreSelected.label = foundItem.title
      }
    }
  }
})
