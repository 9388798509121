
import { defineComponent, ref } from 'vue'
import { mapState, mapActions } from 'vuex'
import Sidebar from '@/assets/components/Sidebar.vue'
import Header from '@/assets/components/Header.vue'
import Footer from '@/assets/components/Footer.vue'
import ThumbPerfil from '@/assets/components/ThumbPerfil.vue'
import HomeContent from '@/views/home/content/index.vue'
import DataTableINeedToSign from '@/views/home/dataTable/dataTableINeedToSign.vue'
import DataTableOthersPendingSign from '@/views/home/dataTable/dataTableOthersPendingSign.vue'
import HeaderContent from '@/views/home/headerContent/index.vue'
import FooterContent from '@/views/home/footerContent/index.vue'
import { TokenService } from '@/services/TokenService'
import { ListEnvelope } from '@/services/responses/ListEnvelopeResponse'
import { AccountService } from '@/services/AccountService'
import { UserService } from '@/services/UserService'
import {
  ContainerOutlined,
  FileTextOutlined,
  EyeOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  name: 'MainPage',
  components: {
    ThumbPerfil,
    Sidebar,
    Header,
    Footer,
    HomeContent,
    DataTableINeedToSign,
    DataTableOthersPendingSign,
    HeaderContent,
    FooterContent,
    ContainerOutlined,
    FileTextOutlined,
    EyeOutlined
  },
  data: () => ({
    visible: ref<boolean>(false),
    serviceAccount: ref<AccountService>(),
    serviceUser: ref<UserService>(),
    isMobile: ref<boolean>(false),
    accountName: ref<string>(''),
    accountMail: ref<string>(''),
    accountPlanName: ref<string>(''),
    accountType: ref<any>(''),
    userData: ref<any>(null),
    envelopesConsummation: ref<number>(0),
    envelopesLimit: ref<number>(0),
    whatsappConsummation: ref<number>(0),
    whatsappLimit: ref<number>(0),
    isActionAdminOrSysAdmin: ref<boolean>(false)
  }),
  watch: {
    openNewDocument () {
      this.visible = this.openNewDocument
    }
  },
  computed: {
    ...mapState('authenticator', ['email']),
    ...mapState('authenticator', ['logo']),
    ...mapState('sections', [
        'openNewDocument',
        'envelopes',
        'envelopesINeedToSign',
        'envelopesOthersPendingSign',
        'isHome',
        'treeSelected',
        'pagePagination',
        'currentStatus'
    ]),

    hasEnvelope (): boolean {
      return this.envelopes?.data.length > 0
    },
    envelops (): ListEnvelope[] {
      return this.envelopes !== null ? this.envelopes?.data : []
    },
    envelopsINeedToSign (): ListEnvelope[] {
      return this.envelopesINeedToSign !== null ? this.envelopesINeedToSign?.data : []
    },
    envelopsOthersPendingSign (): ListEnvelope[] {
      return this.envelopesOthersPendingSign !== null ? this.envelopesOthersPendingSign?.data : []
    }
  },
  async mounted () {
    this.serviceAccount = AccountService.getInstance()
    this.serviceUser = UserService.getInstance()

    const accountData = await this.serviceAccount.GetAccountData()
    const userDataByToken = TokenService.getUserData()
    this.accountName = accountData.name
    this.accountMail = userDataByToken.userEmail
    this.accountPlanName = accountData.planName
    this.accountType = accountData.type

    const accountWhastapp = await this.serviceAccount.GetLimitWhatsappAccount(accountData.id)
    this.whatsappConsummation = accountWhastapp.whatsappConsummation
    this.whatsappLimit = accountWhastapp.whatsappLimit

    const accountEnvelopes = await this.serviceAccount.GetLimitEnvelopesAccount(accountData.id)
    this.envelopesLimit = accountEnvelopes.envelopesLimit
    this.envelopesConsummation = accountEnvelopes.envelopesConsummation

    this.userData = await this.serviceUser.getDataUserById(userDataByToken.userId)
    const typeAccount = this.userData.userAccounts[0].type
    const isSysAdmin = this.userData.isSysAdmin
    if (typeAccount === 2 || isSysAdmin) this.isActionAdminOrSysAdmin = true

    if (window.innerWidth < 768) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  },
  methods: {
    ...mapActions('sections', [
      'fetchEnvelops',
      'setOpenNewDocument',
      'setHomePage',
      'fetchEnvelopesByUser',
      'setTreeSelected',
      'setStatusList',
      'setCurrentStatus',
      'setPagePagination',
      'setFolderId',
      'setFolderName'
    ]),
    getEmail (): string {
      const userData = TokenService.getUserData()
      return userData.userEmail
    },
    newDocument () {
      this.$router.push({
        name: 'novoDocumento'
      })
    },
    async getEnvelopesByStatus (status: number) {
      this.setHomePage(false)
      this.setCurrentStatus(status)
      this.setTreeSelected(['0'])
      const page = this.pagePagination !== undefined ? this.pagePagination : 1
      this.setStatusList(status)
      await this.fetchEnvelopesByUser({ page: page, size: null, status: status, filtered: null })
    },
    async changePlan () {
      const accountInfo: any = await this.serviceAccount?.GetAccountData()
      const dataForChangePlan = {
        reference: 1,
        id: accountInfo.id,
        name: accountInfo.name === null ? '' : accountInfo.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        cpfCnpj: accountInfo.cpfCnpj,
        domain: accountInfo.domain,
        phone: accountInfo.phone,
        address: accountInfo.address === null ? '' : accountInfo.address.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        addressNumber: accountInfo.addressNumber,
        zipCode: accountInfo.zipCode,
        city: accountInfo.city,
        state: accountInfo.state,
        district: accountInfo.district,
        contact: accountInfo.contact,
        planName: accountInfo.planName.toUpperCase(),
        planId: accountInfo.planId,
        productToken: process.env.VUE_APP_ROOT_PRODUCT_TOKEN,
        productUrl: process.env.VUE_APP_ROOT_PRODUCT_URL,
        isPartner: accountInfo.isPartner,
        partnerId: accountInfo.partnerId
      }

      const dataForChangePlanEncode = btoa(JSON.stringify(dataForChangePlan))
      window.location.href = process.env.VUE_APP_ROOT_GATEWAY_URL + '/plans?data=' + dataForChangePlanEncode
    }
  }
})
