
import { defineComponent, ref } from 'vue'
import { AccountService } from '@/services/AccountService'
import { UserService } from '@/services/UserService'
import { TokenService } from '@/services/TokenService'
import { CepService } from '@/services/CepService'
import { mapState, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import { notification } from 'ant-design-vue'

import { RedoOutlined, InfoCircleTwoTone } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'AccountConfiguration',
  components: {
    RedoOutlined,
    InfoCircleTwoTone
  },
  data: () => ({
    service: null as any,
    serviceUser: ref<UserService>(),
    userData: ref<any>(null),
    isActionAdminOrSysAdmin: ref<boolean>(false),
    isSysAdmin: ref<boolean>(false),
    isAdmin: ref<boolean>(false),
    typeAccount: ref<any>(null),
    formValues: {
        name: '' as string,
        surname: '' as string,
        domain: '' as string,
        cpfCnpj: '' as string,
        zipCode: '' as string,
        city: '' as string,
        state: '' as string,
        district: '' as string,
        address: '' as string,
        addressNumber: null as any,
        planName: '' as string,
        type: 0 as number,
        integrationToken: '' as string
    },
    cep: null as any
  }),
  async created () {
    this.formValues = cloneDeep(this.accountInfo)
    this.service = AccountService.getInstance()
    this.serviceUser = UserService.getInstance()
    const userDataByToken = TokenService.getUserData()
    this.userData = await this.serviceUser.getDataUserById(userDataByToken.userId)
    this.typeAccount = this.userData.userAccounts[0].type
    this.isSysAdmin = this.userData.isSysAdmin
    if (this.typeAccount === 2 || this.isSysAdmin) this.isActionAdminOrSysAdmin = true
    if (this.typeAccount === 2) this.isAdmin = true
  },
  watch: {
    accountInfo () {
      this.formValues = cloneDeep(this.accountInfo)
    }
  },
  computed: {
    ...mapState('configuracao', ['accountInfo', 'userInfo']),
    isPessoaFisica () {
      if (this.formValues.type === 2) {
        return true
      }

      return false
    },
    haveToken () {
      if (this.formValues.integrationToken != null) {
        return true
      }

      return false
    }
  },
  methods: {
    ...mapActions('configuracao', ['setAccountInfo']),
    changePlan () {
      const dataForChangePlan = {
        reference: 1,
        id: this.accountInfo.id,
        name: this.accountInfo.name === null ? '' : this.accountInfo.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        cpfCnpj: this.accountInfo.cpfCnpj,
        domain: this.accountInfo.domain,
        phone: this.accountInfo.phone,
        address: this.accountInfo.address === null ? '' : this.accountInfo.address.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        addressNumber: this.accountInfo.addressNumber,
        zipCode: this.accountInfo.zipCode,
        city: this.accountInfo.city,
        state: this.accountInfo.state,
        district: this.accountInfo.district,
        contact: this.userInfo.email,
        planName: this.accountInfo.planName.toUpperCase(),
        planId: this.accountInfo.planId,
        productToken: process.env.VUE_APP_ROOT_PRODUCT_TOKEN,
        productUrl: process.env.VUE_APP_ROOT_PRODUCT_URL,
        partnerId: this.accountInfo.partnerId
      }

      const dataForChangePlanEncode = btoa(JSON.stringify(dataForChangePlan))
      window.location.href = process.env.VUE_APP_ROOT_GATEWAY_URL + '/plans?data=' + dataForChangePlanEncode
    },
    async setZipCode () {
      this.cep = await CepService.getInstance().searchAddress(
        this.formValues.zipCode!.replace('-', '')
      )
      if (this.cep) {
        this.formValues.address = this.cep.logradouro
        this.formValues.state = this.cep.uf
        this.formValues.district = this.cep.bairro
        this.formValues.city = this.cep.localidade
      }
    },
    async save () {
      try {
        await this.service.accountUpdate(this.formValues)

        const resp = await this.service.GetAccountData()
        this.setAccountInfo(resp)

        this.formValues = cloneDeep(this.accountInfo)

        notification.success({
          message: 'Conta Alterada',
          description: 'As informações da sua conta foram alteradas com sucesso'
        })
      } catch (err) {
        console.error(err)
      }
    },
    async generateToken () {
      try {
        const userIntegration = await this.service.createUserIntegration()
        await this.service.GetAccountData()

        notification.success({
          message: 'Token Gerado',
          description: 'O Token foi gerado com sucesso'
        })

        this.formValues = cloneDeep(this.accountInfo)
        this.formValues.integrationToken = userIntegration.token
      } catch (err) {
        notification.error({
          message: 'Falha ao gerar Token',
          description: 'Ocorreu uma falha no momento de gerar o Token'
        })
      }
    }
  }
})
