
import { defineComponent, reactive, ref, UnwrapRef, h } from 'vue'
import { CepService } from '@/services/CepService'
import { AccountService } from '@/services/AccountService'
import { CreateAccountCompanyCommandRequest } from '@/services/requests/CreateAccountCompanyCommandRequest '
import { CreateAccountIndividualCommandRequest } from '@/services/requests/CreateAccountIndividualCommandRequest '
import { ValidateService } from '@/services/ValidateService'
import { CepResponse } from '@/services/responses/CepResponse'
import { UserService } from '@/services/UserService'
import { TokenService } from '@/services/TokenService'
import ModalTerms from '../modal/modalTerms.vue'
import ModalPolicy from '../modal/modalPolicy.vue'
import Footer from '@/assets/components/Footer.vue'
import select from 'ant-design-vue/lib/select'
import steps from 'ant-design-vue/lib/steps'
import locale from 'ant-design-vue/es/date-picker/locale/pt_BR'
import {
  LoadingOutlined,
  InfoCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled
} from '@ant-design/icons-vue'
import {
  message,
  notification
} from 'ant-design-vue'
import spin from 'ant-design-vue/lib/spin'
import moment, { Moment } from 'moment'

interface FormEnterprise {
  name: string | null
  cpfCnpj: string | null
  phone: string | null
  address: string | null
  zipCode: string | null
  city: string | null
  state: string | null
  district: string | null
}
interface FormIndividual {
  name: string | null
  email: string | null
  cpf: string | null
  birthDate: string | null
  password: string | null
}

enum ETypeAccount {
  INDIVIDUAL = 1,
  COMPANY = 2
}

export default defineComponent({
  name: 'RegistrationAccount',
  components: {
    ModalTerms,
    ModalPolicy,
    Footer,
    InfoCircleFilled,
    CheckCircleFilled,
    CloseCircleFilled
  },
  setup () {
    const visible = ref<boolean>(false)
    const textVisible = ref<number>(0)

    const showModal = (number: number) => {
      textVisible.value = number
      visible.value = true
    }
    const handleOk = (e: MouseEvent) => {
      visible.value = false
    }
    return {
      textVisible,
      visible,
      showModal,
      handleOk
    }
  },

  data: () => ({
    buttonChecked: false,
    spin: true,
    success: false,
    isPartner: false,
    locale,
    indicator: h(LoadingOutlined, {
      style: {
        fontSize: '120px'
      },
      spin: true
    }),
    valueDate: ref<Moment>(moment(new Date(), 'DD/MM/YYYY')),
    newValueDate: ref(''),
    cep: ref<CepResponse>({
      cep: '',
      logradouro: '',
      complemento: '',
      bairro: '',
      localidade: '',
      uf: '',
      ibge: '',
      gia: '',
      ddd: '',
      siafi: ''
    }),
    registrationExternal: {
      name: '' as string,
      cpf: '' as string,
      birthDate: null as any,
      password: '' as string
    },
    confirmEmail: null as string | null,
    formIndividual: reactive<FormIndividual>({
      name: null as string | null,
      email: null as string | null,
      cpf: null as string | null,
      birthDate: null as string | null,
      password: null as string | null
    }) as UnwrapRef<FormIndividual>,
    formEnterprise: reactive<FormEnterprise>({
      name: null,
      cpfCnpj: null,
      phone: null,
      address: null,
      zipCode: null,
      city: null,
      state: null,
      district: null
    }) as UnwrapRef<FormEnterprise>,
    addressNumber: ref<string | null>(null),
    current: ref<number>(0),
    message,
    typeAccount: ETypeAccount.INDIVIDUAL as ETypeAccount,
    stepsExternal: [
      {
        title: 'Usuário',
        content: '1'
      },
      {
        title: 'Finalizado',
        content: '2',
        status: 'finish'
      }
    ] as Array<{
      title: string
      content: string
      status: string | undefined | null
    }>,
    steps: [
      {
        title: 'Selecionar',
        content: '1'
      },
      {
        title: 'Usuário',
        content: '3'
      },
      {
        title: 'Finalizado',
        content: '4',
        status: 'finish'
      }
    ] as Array<{
      title: string
      content: string
      status: string | undefined | null
    }>,
    partnerHash: '' as string | null | undefined,
    partner: '' as string | undefined | null,
    isCustomerPartner: false,
    isMobile: ref<boolean>(false),
    version: ref<any>(process.env.VUE_APP_VERSION),
    finalStep: ref<boolean>(false),
    isFaciap: ref<boolean>(false)
  }),

  created () {
    if (this.isExternal) {
      const key = this.$route.query.key?.toString()

      if (key) {
        TokenService.setToken(key!)
      }
    }

    this.partner = this.$route.query.partner?.toString()
    this.partnerHash = this.$route.query.partnerHash?.toString()

    if (this.partner && this.partnerHash) this.isCustomerPartner = true

    if (this.isCustomerPartner && this.partnerHash === 'affe9612-27b6-4c58-8923-4d8772461514') {
      this.isFaciap = true
    }
  },

  mounted () {
    if (window.innerWidth < 768) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  },

  beforeUnmount () {
    if (this.isExternal) {
      TokenService.clear()
    }
  },

  watch: {
    valueDate () {
      const format = this.valueDate?.format()
      if (this.isExternal) {
        this.registrationExternal.birthDate = format
      } else {
        this.formIndividual.birthDate = format!
      }
    }
  },
  computed: {
    canCreate () {
      let dec = false

      if (this.steps[this.current].content === '2') {
        Object.values(this.formEnterprise).forEach((item) => {
          if (!item) {
            dec = true
          }
        })
      }

      if (this.steps[this.current].content === '3') {
        if (!this.buttonChecked) {
          dec = true
        }
        Object.values(this.formIndividual).forEach((item) => {
          if (!item) {
            dec = true
          }
        })
      }
      return dec
    },

    isExternal () {
      return this.$route.name === 'cadastro-externo'
    }
  },
  methods: {
    AcceptTerms () {
      this.buttonChecked = !this.buttonChecked
    },
    goToLogin () {
      TokenService.clear()
      this.$router.replace('/login')
    },
    checkDate () {
        const parts = this.newValueDate.split('/')
        if (this.newValueDate !== '') {
            const cDate = `${parts[2]}-${parts[1]}-${parts[0]}`
            if (this.checkIsDateValid(cDate)) {
                const cFormat = moment(new Date(cDate)).add(4, 'hours').format('YYYY-MM-DDTHH:mm:ssZ')
                const birthDate = moment(cFormat)
                const currentDate = moment()

                if (birthDate.isBefore(currentDate) && !birthDate.isAfter(currentDate) && !birthDate.isSame(currentDate, 'day')) {
                    if (this.isExternal) {
                        this.registrationExternal.birthDate = cFormat
                    } else {
                        this.formIndividual.birthDate = cFormat!
                    }
                } else {
                    notification.warning({
                        message: 'Data inconsistente',
                        description: 'A data informada deve ser menor que a data atual.'
                    })
                }
            } else {
                notification.error({
                    message: 'Data inválida',
                    description: 'A data informada está em um formato inválido, use o padrão DD/MM/AAAA. Ex: 01/12/1900'
                })
            }
        }
    },
    checkIsDateValid (dateString: any) {
        const checkYear = dateString.split('-')[0]
        if (checkYear.length === 4) {
            const cDate: any = new Date(dateString)
            return !isNaN(cDate) && cDate instanceof Date && cDate.getFullYear() >= 1900
        } else {
            return false
        }
    },
    async createAcount () {
      this.isPartner = this.$route.query.partnerHash !== null && this.$route.query.partnerHash !== undefined
      this.spin = true
      try {
        const service = AccountService.getInstance()
        if (this.typeAccount === ETypeAccount.COMPANY) {
          const dados = {
            name: this.formEnterprise.name,
            cpfCnpj: this.formEnterprise.cpfCnpj,
            phone: this.formEnterprise.phone,
            address: this.formEnterprise.address,
            addressNumber: this.addressNumber?.toString(),
            zipCode: this.formEnterprise.zipCode,
            city: this.formEnterprise.city,
            state: this.formEnterprise.state,
            district: this.formEnterprise.district,
            user: {
              name: this.formIndividual.name,
              email: this.formIndividual.email?.trim().toLowerCase(),
              cpf: this.formIndividual.cpf,
              birthDate: this.formIndividual.birthDate,
              password: this.formIndividual.password
            },
            term: '',
            geolocalization: 'string',
            partner: this.$route.query.partner?.toString(),
            partnerHash: this.$route.query.partnerHash?.toString(),
            partnerClientHash: this.$route.query.clientHash?.toString()
          } as CreateAccountCompanyCommandRequest

          this.finalStep = true
          const response = await service.createCompany(dados)
          if (response) this.success = true
          // redirect to gateway
          if (this.isCustomerPartner && response && !this.isFaciap) {
            const dataForGatewayPlanPartner = {
                reference: 1,
                id: response.id,
                name: response.name,
                cpfCnpj: '',
                domain: '',
                phone: '',
                address: '',
                addressNumber: '',
                zipCode: '',
                city: '',
                state: '',
                district: '',
                contact: '',
                planId: response.planId,
                productToken: process.env.VUE_APP_ROOT_PRODUCT_TOKEN,
                productUrl: process.env.VUE_APP_ROOT_PRODUCT_URL,
                isPartner: response.isPartner,
                partnerId: response.partnerId
            }

            setTimeout(() => {
                const dataForGatewayPlanPartnerEncode = btoa(JSON.stringify(dataForGatewayPlanPartner))
                window.location.href = process.env.VUE_APP_ROOT_GATEWAY_URL + '/plans?data=' + dataForGatewayPlanPartnerEncode
            }, 5000)
          } else {
            if (this.isFaciap) {
              setTimeout(() => {
                window.location.href = process.env.VUE_APP_ROOT_PRODUCT_URL as string
              }, 2000)
            }
          }
        } else {
          const dados = {
            name: this.formIndividual.name,
            email: this.formIndividual.email?.trim().toLowerCase(),
            cpf: this.formIndividual.cpf,
            birthDate: this.formIndividual.birthDate,
            password: this.formIndividual.password,
            term: '',
            geolocalization: '',
            partner: this.$route.query.partner?.toString(),
            partnerHash: this.$route.query.partnerHash?.toString(),
            partnerClientHash: this.$route.query.clientHash?.toString()
          } as CreateAccountIndividualCommandRequest

          this.finalStep = true
          const response = await service.createIndividual(dados)
          if (response) this.success = true
          // redirect to gateway
          if (this.isCustomerPartner && response && !this.isFaciap) {
            const dataForGatewayPlanPartner = {
                reference: 1,
                id: response.id,
                name: response.name,
                cpfCnpj: '',
                domain: '',
                phone: '',
                address: '',
                addressNumber: '',
                zipCode: '',
                city: '',
                state: '',
                district: '',
                contact: '',
                planId: response.planId,
                productToken: process.env.VUE_APP_ROOT_PRODUCT_TOKEN,
                productUrl: process.env.VUE_APP_ROOT_PRODUCT_URL,
                isPartner: response.isPartner,
                partnerId: response.partnerId
            }

            setTimeout(() => {
                const dataForGatewayPlanPartnerEncode = btoa(JSON.stringify(dataForGatewayPlanPartner))
                window.location.href = process.env.VUE_APP_ROOT_GATEWAY_URL + '/plans?data=' + dataForGatewayPlanPartnerEncode
            }, 5000)
          } else {
            if (this.isFaciap) {
              setTimeout(() => {
                window.location.href = process.env.VUE_APP_ROOT_PRODUCT_URL as string
              }, 2000)
            }
          }
        }
      } catch (err) {
        this.finalStep = false
      }
      this.spin = false
    },
    addRegistrationPj () {
      this.steps = [
        {
          title: 'Selecionar',
          content: '1',
          status: undefined
        },
        {
          title: 'Empresa',
          content: '2',
          status: undefined
        },
        {
          title: 'Usuário',
          content: '3',
          status: undefined
        },
        {
          title: 'Finalizado',
          content: '4',
          status: 'finish'
        }
      ]
    },

    addRegistrationPf () {
      this.steps = [
        {
          title: 'Selecionar',
          content: '1',
          status: undefined
        },
        {
          title: 'Usuário',
          content: '3',
          status: undefined
        },
        {
          title: 'Finalizado',
          content: '4',
          status: 'finish'
        }
      ]
    },

    async setZipCode () {
      this.cep = await CepService.getInstance().searchAddress(
        this.formEnterprise.zipCode!.replace('-', '')
      )
      if (this.cep) {
        this.formEnterprise.address = this.cep.logradouro
        this.formEnterprise.state = this.cep.uf
        this.formEnterprise.district = this.cep.bairro
        this.formEnterprise.city = this.cep.localidade
      }
    },

    async createAccountExternal () {
      this.spin = true
      if (Object.values(this.registrationExternal).indexOf('') >= 0) {
        notification.warning({
          message: 'Atenção!',
          description: 'Os dados obrigatórios não foram preenchidos.'
        })
        this.current--
        return
      } else if (this.registrationExternal.birthDate === null) {
        notification.warning({
          message: 'Atenção!',
          description: 'O campo data não foi preenchido corretamente.'
        })
        this.current--
        return
      } else if (!this.buttonChecked) {
        notification.warning({
          message: 'Atenção!',
          description: 'Para continuar você precisa concordar com os termos de uso e política de privacidade.'
        })
        this.current--
        return
      } else {
        if (this.validateCPF(this.registrationExternal.cpf, true)) {
          const service = UserService.getInstance()
          try {
            this.finalStep = true
            this.success = await service.registerData(this.registrationExternal)
          } catch (err) {
            console.error(err)
          }
        } else {
          this.current--
          return
        }
      }
      this.spin = false
    },

    async next () {
      if (this.steps[this.current].content === '3') {
        if (this.formIndividual.email?.trim() === this.confirmEmail?.trim()) {
          this.current++
        } else {
          notification.warning({
            message: 'Atenção!',
            description: 'Os e-mails informados não conferem!'
          })
        }
      } else {
        this.current++
      }

      if (this.isExternal) {
        this.createAccountExternal()
      } else {
        if (this.steps[this.current].content === '4') {
          await this.createAcount()
        }
      }
    },

    prev () {
      // reseta value do btn
      this.buttonChecked = false
      this.current--
    },

    validateCPF (cpf: string | any, isShowMessage: boolean) {
      let isValidCpf = false
      if (cpf.length === 14) {
        isValidCpf = ValidateService.isValidCpf(cpf)
        if (!isValidCpf) {
          if (isShowMessage) {
            notification.warning({
              message: 'Cpf inválido',
              description: 'O CPF inserido é inválido, favor corrigir.'
            })
          }
          return false
        } else {
            return true
        }
      } else if (cpf.length > 14) {
        if (isShowMessage) {
          notification.warning({
            message: 'Cpf inválido',
            description: 'O CPF inserido possui caracteres há mais, favor corrigir.'
          })
        }
        return false
      } else if (cpf.length < 14) {
        notification.warning({
          message: 'Cpf inválido',
          description: 'O CPF inserido possui caracteres há menos, favor corrigir.'
        })
        return false
      }
    },

    handleChange (value: number) {
      if (value === 1) this.addRegistrationPf()
      else this.addRegistrationPj()
    }
  }
})
