import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5abb3b66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "box-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LockOutlined = _resolveComponent("LockOutlined")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tree_select, {
      value: _ctx.cofreSelected.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cofreSelected.value) = $event)),
      "tree-data": _ctx.selectTreeData,
      "field-names": {
        children: 'children',
        value: 'value',
        label: 'title',
      },
      placeholder: "Selecione o Cofre",
      "dropdown-style": { maxHeight: '600px', overflow: 'auto' },
      onChange: _ctx.handleChangeCofre,
      style: {"width":"100%"}
    }, {
      suffixIcon: _withCtx(() => [
        _createVNode(_component_LockOutlined)
      ]),
      _: 1
    }, 8, ["value", "tree-data", "onChange"])
  ]))
}