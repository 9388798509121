import { AxiosInstance } from 'axios'
import { HTTPBaseService } from '@/services/HTTPBaseService'
import { FolderTreeViewResponse } from '@/services/responses/FolderTreeViewResponse'
import { FolderTreeViewResponseV2 } from './responses/FolderTreeViewResponseV2'
import { FolderWithUsersResponse } from './responses/FolderWithUsersResponse'
import { FolderWithUsersAndChildrenResponse } from './responses/FolderWithUsersAndChildrenResponse'
import { AddUsersInFolderRequest } from './requests/AddUsersInFolderRequest'
import { UpdateFolderRequest } from './requests/UpdateFolderRequest'

export class FolderService extends HTTPBaseService {
    private static classInstance?: FolderService;

    constructor () {
      super(process.env.VUE_APP_ROOT_API_SHAR as string)
    }

    public static getInstance () {
      if (!this.classInstance) {
        this.classInstance = new FolderService()
      }
      return this.classInstance
    }

    public async getTreeView (): Promise<FolderTreeViewResponse> {
      const { data } = await this.instance.get<FolderTreeViewResponse>('/core/api/v1/folders/treeview')
      return data
    }

    public async getTreeViewV2 (): Promise<FolderTreeViewResponseV2> {
      const { data } = await this.instance.get<FolderTreeViewResponseV2>('/core/api/v2/folders/treeview')
      return data
    }

    public async getSelectView () : Promise<any> {
      const { data } = await this.instance.get<any>('/core/api/v1/folders/selectview')
      return data
    }

    public async getListFolderWithUser () : Promise<FolderWithUsersResponse[]> {
      const { data } = await this.instance.get<FolderWithUsersResponse[]>('/core/api/v1/folders/users')
      return data
    }

    public async getListFolderWithUserAndChildren () : Promise<FolderWithUsersAndChildrenResponse[]> {
        const { data } = await this.instance.get<FolderWithUsersAndChildrenResponse[]>('/core/api/v1/folders/users-and-children')
        return data
      }

    public async createfolder (name: string, parentId: any = null) : Promise<void> {
      await this.instance.post('/core/api/v1/folders', { name, parentId })
    }

    public async updatefolder (request:UpdateFolderRequest) : Promise<void> {
      await this.instance.put('/core/api/v1/folders', request)
    }

    public async addUserInVault (request:AddUsersInFolderRequest) : Promise<void> {
      await this.instance.put('/core/api/v1/folders/users', request)
    }

    public async deleteFolder (id: number) : Promise<void> {
        await this.instance.delete(`/core/api/v1/folders/${id}`)
    }
}
