import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1484f63c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_pagination, {
      class: "footer-content",
      current: _ctx.current,
      "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current) = $event)),
      total: _ctx.pages
    }, null, 8, ["current", "total"])
  ]))
}