
import { defineComponent, ref, reactive, h } from 'vue'
import {
    DeleteOutlined,
    QuestionCircleOutlined,
    EditOutlined,
    UserAddOutlined,
    LockOutlined,
    PlusSquareOutlined
} from '@ant-design/icons-vue'
import { UserService } from '@/services/UserService'
import { notification } from 'ant-design-vue'
import { FolderService } from '@/services/FolderService'
import { FolderWithUsersAndChildrenResponse } from '@/services/responses/FolderWithUsersAndChildrenResponse'
import { AddUsersInFolderRequest } from '@/services/requests/AddUsersInFolderRequest'
import { UpdateFolderRequest } from '@/services/requests/UpdateFolderRequest'
import { trim } from 'lodash'

export default defineComponent({
    name: 'VaultsConfiguration',
    components: {
        LockOutlined,
        UserAddOutlined,
        EditOutlined,
        DeleteOutlined,
        QuestionCircleOutlined,
        PlusSquareOutlined
    },
    data: () => ({
        lengthTitle: 50,
        open: ref<boolean>(false),
        loading: ref<boolean>(false),
        option: ref<number>(1),
        addUserInVault: ref<AddUsersInFolderRequest>({
            folderId: 0,
            userIds: []
        }),
        editFolder: ref<UpdateFolderRequest>(
            {
                id: 0,
                name: ''
            }
        ),
        users: [],
        usersSelect: [],
        columns: [
            {
                title: 'Nome',
                dataIndex: 'name',
                key: 'name',
                slots: { customRender: 'name' },
                fixed: 'center',
                resizable: true,
                width: 15
            },
            {
                title: 'Usuários',
                dataIndex: 'users',
                key: 'users',
                ellipsis: false,
                slots: { customRender: 'users' },
                width: 15
            },
            {
                title: '',
                key: 'action',
                slots: { customRender: 'action' },
                width: 8
            }
        ],
        folders: reactive([] as FolderWithUsersAndChildrenResponse[]),
        userService: null as any,
        folderService: null as any,
        formCreateVault: {
            name: '' as string
        },
        formCreateChildrenVault: {
            name: '' as string
        },
        isAddChildrenVault: ref<boolean>(false),
        recordSelected: [] as any
    }),
    async created () {
        this.userService = UserService.getInstance()
        const users = await this.userService.getUsers(true)
        this.users = users.map((x:any) => ({ label: x.name, value: x.id }))
        this.folderService = FolderService.getInstance()
        await this.loadFolders()
    },
    computed: {
        getFolders () {
            return this.folders
        }
    },
    methods: {
        titleModal () {
            let title = ''
            if (this.option === 1) {
                title = 'Selecionar usuários'
            }
            if (this.option === 2) {
                title = 'Alterar nome do cofre'
            }
            if (this.option === 3) {
                title = 'Adiciona um sub cofre'
            }
            return title
        },

        validTitle (title: string) {
            if (title.length > this.lengthTitle) {
                notification.warning({
                    message: 'Nome de cofre inválido',
                    description: `O nome do cofre deve conter até ${this.lengthTitle} caracteres.`
                })
            }
        },

        checkIsTitleEmpty (title: string) {
            const cleanTitle = trim(title)
            if (cleanTitle.length > 0) return true
            return false
        },

        async loadFolders () {
            this.folders = await this.folderService.getListFolderWithUserAndChildren()
        },

        openAddRemoveUsers (folder: any) {
            this.addUserInVault.folderId = folder.id
            this.addUserInVault.userIds = folder.users.map((x:any) => x.id)
            this.option = 1
            this.open = true
        },

        openEditVault (folder: any) {
            this.editFolder.id = folder.id
            this.editFolder.name = folder.name
            this.option = 2
            this.open = true
        },

        openAddChildrenVault (folder: any) {
            this.recordSelected = folder
            this.option = 3
            this.open = true
        },

        handleResizeColumn (w: any, col: any) {
            col.width = w
        },

        async createVault () {
            this.loading = true
            try {
                const vault = this.formCreateVault.name
                if (vault.trim().length > 0) {
                    await this.folderService.createfolder(this.formCreateVault.name)
                    await this.loadFolders()
                    this.formCreateVault.name = ''
                    notification.success({
                        message: 'Cofre criado com sucesso!'
                    })
                } else {
                    notification.warning({
                        message: 'Falha criar Cofre',
                        description: 'Para criar um cofre preencha com um nome apropriado!'
                    })
                }
            } catch (err) {
                notification.error({
                    message: 'Falha ao criar Cofre',
                    description: 'Erro inesperado'
                })
            }
            this.loading = false
        },

        async handleOk () {
            this.loading = true
            if (this.option === 1) {
                try {
                    await this.folderService.addUserInVault(this.addUserInVault)
                    this.open = false
                    this.addUserInVault = {
                        folderId: 0,
                        userIds: []
                    }
                } catch (err) {
                    notification.error({
                        message: 'Falha ao alterar usuários do cofre',
                        description: 'Erro inesperado'
                    })
                }
            } else if (this.option === 2) {
                try {
                    this.editFolder.name = this.editFolder.name.trim()
                    await this.folderService.updatefolder(this.editFolder)
                    this.open = false
                    this.editFolder = {
                        id: 0,
                        name: ''
                    }
                } catch (err) {
                    notification.error({
                        message: 'Falha ao alterar cofre',
                        description: 'Erro inesperado'
                    })
                }
            } else {
                try {
                    const parentId = this.recordSelected.id
                    const vault = this.formCreateChildrenVault.name
                    if (vault.trim().length > 0) {
                        await this.folderService.createfolder(this.formCreateChildrenVault.name, parentId)
                        await this.loadFolders()
                        this.formCreateChildrenVault.name = ''
                        this.isAddChildrenVault = false
                        notification.success({
                            message: 'Sub cofre adicionado com sucesso!'
                        })
                        this.open = false
                    } else {
                        notification.warning({
                            message: 'Falha criar um Sub Cofre',
                            description: 'Para criar um sub cofre preencha com um nome apropriado!'
                        })
                    }
                } catch (err) {
                    notification.error({
                        message: 'Falha ao criar Sub Cofre',
                        description: 'Erro inesperado'
                    })
                }
            }
            this.loading = false
            await this.loadFolders()
        },

        async remove (folder:any) {
            try {
                await this.folderService.deleteFolder(folder.id)
                await this.loadFolders()
            } catch (err) {
                notification.error({
                    message: `Falha ao remover o cofre ${folder.name}`,
                    description: 'Erro inesperado'
                })
            }
        },

        handleCancel () {
            this.open = false
            this.formCreateVault.name = ''
            this.formCreateChildrenVault.name = ''
        }
    }
})
