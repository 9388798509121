import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1feae5aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { style: {"width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeftOutlined = _resolveComponent("ArrowLeftOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_DrawerHistory = _resolveComponent("DrawerHistory")!
  const _component_a_page_header = _resolveComponent("a-page-header")!

  return (_openBlock(), _createBlock(_component_a_page_header, { ghost: false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.envelops.length == 0)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              onClick: _ctx.returnToVault
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ArrowLeftOutlined)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_input_search, {
            style: {"margin":"2px","min-width":"240px"},
            value: _ctx.value,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            placeholder: "Pesquise pelo titulo",
            loading: _ctx.loading,
            "enter-button": "",
            onSearch: _ctx.onSearch,
            "allow-clear": ""
          }, null, 8, ["value", "loading", "onSearch"])
        ])
      ]),
      _createVNode(_component_DrawerHistory)
    ]),
    _: 1
  }))
}