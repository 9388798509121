
  import { defineComponent, ref, reactive } from 'vue'
  import { mapActions, mapState } from 'vuex'
  import { FolderService } from '@/services/FolderService'
  import { EnvelopeService } from '@/services/EnvelopeService'
  import { notification } from 'ant-design-vue'
  import {
    CloseOutlined
  } from '@ant-design/icons-vue'
  export default defineComponent({
    name: 'ChangeCofreByDocument',
    props: {
      vaults: {
        type: Object
      },
      infoDocument: {
        type: Object
      }
    },
    components: {
      CloseOutlined
    },
    computed: {
    ...mapState('sections', ['currentStatus', 'pagePagination'])
    },
    data () {
      return {
        folderService: ref<FolderService | null>(null),
        folderSelected: null as any,
        foldersList: ref<any>([]),
        loading: ref<boolean>(false),
        radioStyle: reactive({
          display: 'flex',
          height: '20px',
          lineHeight: '15px',
          marginTop: '10px'
        })
      }
    },
    methods: {
      ...mapActions('sections', ['fetchEnvelopesByVault', 'fetchEnvelopesByUser']),
      async getFolder () {
        const folders = await this.folderService?.getSelectView()
        if (folders) {
          this.foldersList = folders.childrens
        }
      },
      async moveDocument () {
        if (this.folderSelected !== null) {
          this.loading = true
          try {
            const envelopeService = EnvelopeService.getInstance()
            await envelopeService.changeVaultEnvelope(this.infoDocument?.id, this.folderSelected)

            this.closeModalMoveDocument()

            notification.success({
              message: 'Documento Movido',
              description: `O documento: ${this.infoDocument?.title}, foi movido para o outro cofre.`
            })

            if (this.currentStatus == null) {
              await this.fetchEnvelopesByVault({
                folderId: this.infoDocument?.folderId,
                status: null,
                page: this.pagePagination,
                size: 9
              })
            } else {
              await this.fetchEnvelopesByUser({
                page: this.pagePagination,
                size: 9,
                status: this.currentStatus,
                filtered: null
              })
            }
          } catch (err) {}
          this.loading = false
        } else {
          notification.warning({
            message: 'Falha ao mover documento',
            description: 'Para mover o documento selecione um cofre.'
          })
        }
      },
      closeModalMoveDocument () {
        this.folderSelected = null
        this.$emit('closeModalMoveDocument')
      }
    },
    async mounted () {
      this.folderService = FolderService.getInstance()
      await this.getFolder()
    }
  })
