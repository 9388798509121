
import { defineComponent, ref } from 'vue'
import { FolderService } from '@/services/FolderService'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'FolderSelectNewDocument',
  components: { },
  props: {
    selected: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      folderService: ref<FolderService | null>(null),
      cofreSelected: ref<{ value: any, label: string }>({ value: '', label: '' }),
      selectTreeData: ref<any>([])
    }
  },
  computed: {
    ...mapState('sections', ['folderId'])
  },
  methods: {
    ...mapActions('sections', ['setFolderId', 'setFolderName']),
    async getFolder () {
      const folders = await this.folderService?.getSelectView()
      if (folders) {
        this.selectTreeData = this.transformData(folders.childrens || [])
      }
    },
    transformData (nodes: any, parentId = 0) {
      return nodes.map((node: any) => {
        const transformedNode = {
          title: node.name,
          value: node.id,
          key: node.id,
          children: node.childrens ? this.transformData(node.childrens) : []
        }
        return transformedNode
      })
    },
    findItemInTree (treeData: any, targetValue: any) {
      for (const node of treeData) {
        if (node.value === targetValue) {
          return node
        }

        if (node.children && node.children.length > 0) {
          const foundInChildren: any = this.findItemInTree(node.children, targetValue)
          if (foundInChildren) {
            return foundInChildren
          }
        }
      }
      return null
    },
    formatInfoCofreSelected (cofreSelected: any) {
      const foundItem = this.findItemInTree(this.selectTreeData, cofreSelected)
      return foundItem ? { id: foundItem.value, name: foundItem.title } : null
    },
    handleChangeCofre (value: any) {
      const infoCofreSelected = this.formatInfoCofreSelected(value)
      this.$emit('handleChangeCofre', value, infoCofreSelected)
    }
  },
  async mounted () {
    this.folderService = FolderService.getInstance()
    await this.getFolder()

    if (!localStorage.getItem('folderId') || localStorage.getItem('folderId') === '0') {
      const firstNode = this.selectTreeData[0]
      this.setFolderId(parseInt(firstNode.value))
      this.setFolderName(firstNode.title)
      this.cofreSelected.value = firstNode.value
      this.cofreSelected.label = firstNode.title
    } else {
      const foundItem = this.findItemInTree(this.selectTreeData, Number(localStorage.getItem('folderId')))
      if (foundItem) {
        this.setFolderId(parseInt(foundItem.value))
        this.setFolderName(foundItem.name)
        this.cofreSelected.value = foundItem.value
        this.cofreSelected.label = foundItem.title
      }
    }
  },
  updated () {
    if (isNaN(Number(localStorage.getItem('folderId'))) && this.cofreSelected.value.length === 0 && this.selectTreeData.length > 0) {
      const firstNode = this.selectTreeData[0]
      this.setFolderId(parseInt(firstNode.value))
      this.setFolderName(firstNode.title)
      this.cofreSelected.value = firstNode.value
      this.cofreSelected.label = firstNode.title
      this.handleChangeCofre(firstNode.value)
    }
  }
})
